/* eslint-disable prefer-const */
class TokenService {
  static getLocalRefreshToken = () => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return currentUser?.refresh;
  };

  static getLocalAccessToken = () => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return currentUser?.access;
  };

  static updateLocalAccessToken = (ecommerceToken) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    currentUser.access = ecommerceToken;
    localStorage.setItem("ecommerceToken", JSON.stringify(ecommerceToken));
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  };

  static setcurrentUser = (currentUser) => {
    console.log("currentUser", JSON.stringify(currentUser));
    localStorage.setItem("ecommerceToken", JSON.stringify(currentUser?.access));
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  };

  static removecurrentUser() {
    localStorage.removeItem("ecommerceToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userDetails");
  }
}

export default TokenService;
