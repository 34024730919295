/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { TextField, Grid, MenuItem, Typography } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import MDButton from "components/MDButton";
import CategoriesService from "services/CategoriesService";
import CouponsService from "services/CouponsService";
import { toast } from "react-toastify";
import { OffersService } from "services/OffersService";
import { ProductsService } from "services/ProductsService";
export const CreateOffer = ({ direction, offer, isCreate, backToPrevious }) => {
  ;
  console.log(offer);
  console.log(isCreate);
  const FILE_SIZE = 524288;
  const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
  // const phoneNumberRegEx = /^[0-1]{2}[0-9]{9}/;
  // const PasswordRegEx = /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
  const offerValidation = yup.object().shape({
    en_name: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    ar_name: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    en_description: yup.string(),
    ar_description: yup.string(),
    price: yup.number().min(0.5).required("Required !"),
    start_date: yup.string(),
    end_date: yup.string(),
    product_id: yup.string().required("Required !"),
  });
  const initialValue = useMemo(() => {
    if (isCreate)
      return {
        en_name: "",
        ar_name: "",
        en_description: "",
        ar_description: "",
        start_date: "",
        end_date: "",
        price: 0,
        product_id: "",
      };
    else
      return {
        en_name: offer?.en_name,
        ar_name: offer?.ar_name,
        en_description: offer?.en_description,
        ar_description: offer?.ar_description,
        start_date: offer?.en_description,
        end_date: offer?.ar_description,
        price: offer?.price,
        product_id: offer?.product_id,
      };
  }, [isCreate]);

  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const [file, setFile] = useState();

  function handleChange(e) {
    setSelectedFile(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    imageStyle.display = "inline-block";
    setStyle(imageStyle);
  }

  const handleSubmit = (values, props) => {
    if (selectedFile == null) {
      toast.error("Image is required");
      return;
    }
    var data = new FormData();
    Object.keys(values).forEach((e) => {
      data.append(e, values[e]);
    });
    if (selectedFile) data.append("image", selectedFile);
    if (isCreate) {
      OffersService.addOffer(data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    } else {
      OffersService.updateOffer(offer.id, data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    }
  };
  useEffect(() => {
    ;

    ProductsService.getAllProducts()
      .then((resp) => {
        setProducts(resp.data);
        if (!isCreate) setProduct(resp.data.find((c) => c.id === initialValue?.product_id));
      })
      .catch((error) => {});
  }, []);
  let imageStyle = {
    display: "none",
  };
  const [style, setStyle] = useState(imageStyle);
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValue}
        validationSchema={offerValidation}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            en_name,
            ar_name,
            en_description,
            ar_description,
            start_date,
            end_date,
            price,
            product_id,
          } = props.values;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالإنكليزي" : "English Name"}
                    name="en_name"
                    variant="outlined"
                    margin="dense"
                    value={en_name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="en_name" />}
                    error={props.errors.en_name && props.touched.en_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالعربي" : "Arabic Name"}
                    name="ar_name"
                    variant="outlined"
                    margin="dense"
                    value={ar_name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="ar_name" />}
                    error={props.errors.ar_name && props.touched.ar_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالإنكليزي" : "English Description"}
                    name="en_description"
                    variant="outlined"
                    margin="dense"
                    value={en_description}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="en_description" />}
                    error={props.errors.en_description && props.touched.en_description}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالعربي" : "Arabic Description"}
                    name="ar_description"
                    variant="outlined"
                    margin="dense"
                    value={ar_description}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="ar_description" />}
                    error={props.errors.ar_description && props.touched.ar_description}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <h6>{direction == "rtl" ? "تاريخ بدء العرض" : "Start Date"}</h6>
                  <TextField
                    name="start_date"
                    variant="outlined"
                    margin="dense"
                    type="date"
                    value={start_date}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="start_date" />}
                    error={props.errors.start_date && props.touched.start_date}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <h6>{direction == "rtl" ? "تاريخ نهاية العرض" : "End Date"}</h6>
                  <TextField
                    name="end_date"
                    variant="outlined"
                    margin="dense"
                    value={!!end_date ? end_date : ""}
                    fullWidth
                    type="date"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="end_date" />}
                    error={props.errors.end_date && props.touched.end_date}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "سعر العرض ($)" : "($) Offer Selling Price"}
                    name="price"
                    variant="outlined"
                    margin="dense"
                    value={price}
                    fullWidth
                    type="number"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="price" />}
                    error={props.errors.price && props.touched.price}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "المنتج" : "Product"}
                    name="product_id"
                    variant="outlined"
                    margin="dense"
                    value={product_id}
                    fullWidth
                    select
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="product_id" />}
                    error={props.errors.product_id && props.touched.product_id}
                    required
                  >
                    {products != null &&
                      products.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.en_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "صورة العرض" : "First Image"}
                  </Typography>
                  <TextField
                    name="image"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="offer-image"
                    className="mt-2"
                    src={file}
                    width={150}
                    height={150}
                    style={style}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDButton variant="contained" type="submit" color="primary" fullWidth>
                    {direction == "rtl" ? "إضافة" : "Submit"}
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={backToPrevious}
                    variant="contained"
                    type="submit"
                    color="warning"
                    fullWidth
                  >
                    {direction == "rtl" ? "عودة" : "Back"}
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
