/* eslint-disable */
import api from "./Api";
export class OffersService {
  static getAllOffers() {
    return api.get("/offer").then((response) => {
      if (response && response.data) {
        return response.data;
      }
    });
  }
  static addOffer(payload) {
    console.log(payload);
    return api
      .post("/offer", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      });
  }
  static updateOffer(id, payload) {
    console.log(payload);
    return api
      .post("/offer/edit/" + id, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      });
  }
  static deleteOffer(id) {
    return api
      .delete("/offer/delete/" + id, null, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      });
  }
}

export default OffersService;
