/* eslint-disable */
import api from "./Api";
export class LogoService {
  static addColorsAndLogo(payload) {
    console.log(payload);
    return api
      .post("/colors-and-image", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      });
  }
}

export default LogoService;
