// @mui material components
/* eslint-disable */
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
  setDirection,
} from "context";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { CreateCondition } from "./createCondition";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ConditionsService from "services/ConditionsService";

function Conditions() {
  const [conditions, setConditions] = useState([]);
  const [rows, setFiltered] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    direction,
  } = controller;
  const backendUrl = "https://chelloshal-deutschland.de";
  const columns = [
    {
      Header: direction == "ltr" ? "Id" : "المعرّف",
      accessor: (d) => {
        return (
          <MDTypography display="block" variant="button" fontWeight="medium">
            {d.id}
          </MDTypography>
        );
      },
      width: 20,
      dataKey: "id",
    },
    {
      Header: direction == "ltr" ? "English Name" : "الاسم بالإنكليزي",
      accessor: (d) => {
        return (
          <MDTypography display="block" variant="button" fontWeight="medium">
            {d.name}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "name",
    },
    {
      Header: direction == "ltr" ? "Arabic Name" : "الاسم بالعربي",
      accessor: (d) => {
        return (
          <MDTypography display="block" variant="button" fontWeight="medium">
            {d.name_a}
          </MDTypography>
        );
      },
      width: 50,
      dataKey: "name_a",
    },
    {
      Header: direction == "ltr" ? "Actions" : "إعدادات",
      accessor: (d) => {
        return (
          <>
            {" "}
            <MDButton
              onClick={() => showCondition(d)}
              style={{ margin: "0 5px 0 5px", backgroundColor: "lightblue" }}
            >
              {direction == "rtl" ? "تعديل" : "Edit"}
            </MDButton>
            <MDButton
              onClick={() => handleClickOpen(d.id)}
              color="warning"
              style={{ backgroundColor: "red" }}
            >
              {direction == "rtl" ? "حذف" : "Delete"}
            </MDButton>{" "}
          </>
        );
      },
      width: 50,
    },
  ];
  const getConditions = () => {
    ConditionsService.getAllConditions().then((resp) => {
      console.log(resp);
      setFiltered(resp.data);
      setConditions(resp.data);
    });
  };
  useEffect(() => {
    getConditions();
  }, []);

  // custom pagination

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Delete

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const handleClickOpen = (id) => {
    setId(id);
    direction == "rtl"
      ? setMessage("هل تريد حذف هذا العنصر؟")
      : setMessage("Are you sure you want to delete this item");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAction = () => {
    ConditionsService.deleteCondition(id)
      .then((resp) => {
        toast.success("Condition deleted successfully");
        getConditions();
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  const DamageDialog = (props) => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <MDButton color="primary" onClick={handleAction} autoFocus>
            {direction == "ltr" ? "Yes" : "نعم"}
          </MDButton>
          <MDButton color="red" onClick={handleClose}>
            {direction == "ltr" ? "No" : "لا"}
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [search, setSearch] = useState();
  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value);
    if (!!value) {
      let _searched = conditions.filter(e.name.includes(value) || e.name_a.includes(value));
      setFiltered(_searched);
    } else setFiltered(conditions);
  }, 100);
  //
  const [show, setShow] = useState(false);
  const [createNew, setCreate] = useState(false);
  const [_condition, setCondition] = useState(null);
  const showCondition = (d) => {
    setShow(true);
    setCondition(d);
    setCreate(false);
  };
  const addNew = () => {
    setCreate(true);
    setShow(true);
  };
  const back = () => {
    setCreate(false);
    setShow(false);
    setCondition(null);
    getConditions();
  };
  return (
    <DashboardLayout>
      {!show && (
        <>
          {" "}
          <DashboardNavbar name={direction == "rtl" ? "الشروط" : "Condition"} />
          <MDButton onClick={() => addNew()} color="primary">
            {direction == "rtl" ? "إضافة شرط" : "Create Condition"}
          </MDButton>
          <MDInput
            style={{ marginTop: "1em" }}
            placeholder={direction == "rtl" ? "بحث" : "Search"}
            value={search}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              onSearchChange(currentTarget.value);
            }}
          />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {direction == "rtl" ? "جدول الشروط" : "Conditions Table"}
                    </MDTypography>
                  </MDBox>
                  {rows != null && rows.length > 0 && (
                    <DataTable
                      type="conditions"
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                      canSearch={true}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>{" "}
        </>
      )}
      {show && (
        <>
          <DashboardNavbar
            name={direction == "rtl" ? "إضافة أو تعديل شرط" : "Create or Edit Condition"}
          />
          <CreateCondition
            direction={direction}
            isCreate={createNew}
            condition={_condition}
            backToPrevious={() => back()}
          />
        </>
      )}
      <DamageDialog />
    </DashboardLayout>
  );
}

export default Conditions;
