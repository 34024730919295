/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { TextField, Grid, MenuItem, Typography } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ConditionsService from "services/ConditionsService";
export const CreateCondition = ({ condition, isCreate, backToPrevious, direction }) => {
  const FILE_SIZE = 524288;
  const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
  // const phoneNumberRegEx = /^[0-1]{2}[0-9]{9}/;
  // const PasswordRegEx = /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
  const conditionValidation = yup.object().shape({
    name: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    name_a: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    description: yup.string(),
    description_a: yup.string(),
  });
  const initialValue = useMemo(() => {
    if (isCreate)
      return {
        name: "",
        name_a: "",
      };
    else
      return {
        name: condition?.name,
        name_a: condition?.name_a,
        description: condition?.description,
        description_a: condition?.description_a,
      };
  }, [isCreate]);

  const handleSubmit = (values, props) => {
    var data = new FormData();
    Object.keys(values).forEach((e) => {
      data.append(e, values[e]);
    });
    if (isCreate) {
      ConditionsService.addCondition(data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    } else {
      ConditionsService.updateCondition(condition.id, data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValue}
        validationSchema={conditionValidation}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const { name, name_a, description, description_a } = props.values;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالإنكليزي" : "English Name"}
                    name="name"
                    variant="outlined"
                    margin="dense"
                    value={name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="name" />}
                    error={props.errors.name && props.touched.name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالعربي" : "Arabic Name"}
                    name="name_a"
                    variant="outlined"
                    margin="dense"
                    value={name_a}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="name_a" />}
                    error={props.errors.name_a && props.touched.name_a}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالإنكليزي" : "Description"}
                    name="description"
                    variant="outlined"
                    margin="dense"
                    value={description}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="description" />}
                    error={props.errors.description && props.touched.description}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالعربي" : "Arabic Description"}
                    name="description_a"
                    variant="outlined"
                    margin="dense"
                    value={description_a}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="description_a" />}
                    error={props.errors.description_a && props.touched.description_a}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDButton variant="contained" type="submit" color="primary" fullWidth>
                    {direction == "rtl" ? "إضافة" : "Submit"}
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={backToPrevious}
                    variant="contained"
                    type="submit"
                    color="warning"
                    fullWidth
                  >
                    {direction == "rtl" ? "عودة" : "Back"}
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
