/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { TextField, Grid, MenuItem, Typography } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import MDButton from "components/MDButton";
import CategoriesService from "services/CategoriesService";
import CouponsService from "services/CouponsService";
import { toast } from "react-toastify";
import { ProductsService } from "services/ProductsService";
import { StoresService } from "services/StoresService";
export const CreateProduct = ({ direction, product, isCreate, backToPrevious }) => {
  const FILE_SIZE = 524288;
  const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
  const productValidation = yup.object().shape({
    en_name: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    ar_name: yup.string().min(3, "Too Short !").max(30, "Too Long !").required("Required !"),
    entity: yup.number().min(1).required("Required !"),
    selling_price: yup.number().min(0.5).required("Required !"),
    unit_price: yup.number().min(0.5).required("Required !"),
    en_description: yup.string(),
    ar_description: yup.string(),
    brand: yup.string().required("Required !"),
    category_id: yup.string().required("Required !"),
    coupon_id: yup.string().nullable(),
    store_id: yup.string(),
  });
  const initialValue = useMemo(() => {
    if (isCreate)
      return {
        en_name: "",
        ar_name: "",
        de_name,
        en_description: "",
        ar_description: "",
        de_description,
        selling_price: 0,
        unit_price: 0,
        entity: 0,
        category_id: "",
        store_id: "",
        brand: "",
        store_id: 0,
      };
    else
      return {
        en_name: product?.en_name,
        ar_name: product?.ar_name,
        de_name: product?.de_name,
        en_description: product?.en_description,
        ar_description: product?.ar_description,
        de_description: product?.de_description,
        selling_price: product?.selling_price,
        unit_price: product?.unit_price,
        category_id: product?.category_id,
        coupon_id: product?.coupon_id || "",
        store_id: product?.store_id || "",
        entity: product?.entity,
        brand: product?.brand,
      };
  }, [isCreate]);

  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState(null);
  const [stores, setStores] = useState(null);

  const [coupon, setCoupon] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [store, setStore] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);
  const [selectedFile7, setSelectedFile7] = useState(null);
  const [selectedFile8, setSelectedFile8] = useState(null);

  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();
  const [file4, setFile4] = useState();
  const [file5, setFile5] = useState();
  const [file6, setFile6] = useState();
  const [file7, setFile7] = useState();
  const [file8, setFile8] = useState();
  function handleChange(e) {
    setSelectedFile(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    imageStyle.display = "inline-block";
    setStyle(imageStyle);
  }
  function handleChange1(e) {
    setSelectedFile1(e.target.files[0]);
    setFile1(URL.createObjectURL(e.target.files[0]));
    imageStyle1.display = "inline-block";
    setStyle1(imageStyle1);
  }
  function handleChange2(e) {
    setSelectedFile2(e.target.files[0]);
    setFile2(URL.createObjectURL(e.target.files[0]));
    imageStyle2.display = "inline-block";
    setStyle2(imageStyle2);
  }
  function handleChange3(e) {
    setSelectedFile3(e.target.files[0]);
    setFile3(URL.createObjectURL(e.target.files[0]));
    imageStyle3.display = "inline-block";
    setStyle3(imageStyle3);
  }
  function handleChange4(e) {
    setSelectedFile4(e.target.files[0]);
    setFile4(URL.createObjectURL(e.target.files[0]));
    imageStyle4.display = "inline-block";
    setStyle4(imageStyle4);
  }
  function handleChange5(e) {
    setSelectedFile5(e.target.files[0]);
    setFile5(URL.createObjectURL(e.target.files[0]));
    imageStyle5.display = "inline-block";
    setStyle5(imageStyle5);
  }
  function handleChange6(e) {
    setSelectedFile6(e.target.files[0]);
    setFile6(URL.createObjectURL(e.target.files[0]));
    imageStyle6.display = "inline-block";
    setStyle6(imageStyle6);
  }
  function handleChange7(e) {
    setSelectedFile7(e.target.files[0]);
    setFile7(URL.createObjectURL(e.target.files[0]));
    imageStyle7.display = "inline-block";
    setStyle7(imageStyle7);
  }
  function handleChange8(e) {
    setSelectedFile8(e.target.files[0]);
    setFile8(URL.createObjectURL(e.target.files[0]));
    imageStyle8.display = "inline-block";
    setStyle8(imageStyle7);
  }
  let imageStyle = {
    display: "none",
  };
  let imageStyle1 = {
    display: "none",
  };
  let imageStyle2 = {
    display: "none",
  };
  let imageStyle3 = {
    display: "none",
  };
  let imageStyle4 = {
    display: "none",
  };
  let imageStyle5 = {
    display: "none",
  };
  let imageStyle6 = {
    display: "none",
  };
  let imageStyle7 = {
    display: "none",
  };
  let imageStyle8 = {
    display: "none",
  };
  const colorsPalette = [
    {
      name: "Dark Blue",
      value: "#0071c5",
    },
    {
      name: "Turquoise",
      value: "#40E0D0",
    },
    {
      name: "Green",
      value: "#008000",
    },
    {
      name: "Yellow",
      value: "#FFD700",
    },
    {
      name: "Orange",
      value: "#FF8C00",
    },
    {
      name: "Red",
      value: "#FF0000",
    },
    {
      name: "Black",
      value: "#000",
    },
  ];
  const [style, setStyle] = useState(imageStyle);
  const [color0, setColor0] = useState("");
  const [style1, setStyle1] = useState(imageStyle1);
  const [color1, setColor1] = useState("");
  const [style2, setStyle2] = useState(imageStyle2);
  const [color2, setColor2] = useState("");
  const [style3, setStyle3] = useState(imageStyle3);
  const [color3, setColor3] = useState("");
  const [style4, setStyle4] = useState(imageStyle4);
  const [color4, setColor4] = useState("");
  const [style5, setStyle5] = useState(imageStyle5);
  const [color5, setColor5] = useState("");
  const [style6, setStyle6] = useState(imageStyle6);
  const [color6, setColor6] = useState("");
  const [style7, setStyle7] = useState(imageStyle7);
  const [color7, setColor7] = useState("");
  const [style8, setStyle8] = useState(imageStyle8);
  const [color8, setColor8] = useState("");
  const handleSubmit = (values, props) => {
    var data = new FormData();
    Object.keys(values).forEach((e) => {
      data.append(e, values[e]);
    });
    let colors = [];
    if (selectedFile) {
      colors.push(color0);
      data.append("image", selectedFile);
    }
    if (selectedFile1) {
      colors.push(color1);
      data.append("image_2", selectedFile1);
    }
    if (selectedFile2) {
      colors.push(color2);
      data.append("image_3", selectedFile2);
    }
    if (selectedFile3) {
      colors.push(color3);
      data.append("image_4", selectedFile3);
    }
    if (selectedFile4) {
      colors.push(color4);
      data.append("image_5", selectedFile4);
    }
    if (selectedFile5) {
      colors.push(color5);
      data.append("image_6", selectedFile5);
    }
    if (selectedFile6) {
      colors.push(color6);
      data.append("image_7", selectedFile6);
    }
    if (selectedFile7) {
      colors.push(color7);
      data.append("image_8", selectedFile7);
    }
    if (selectedFile8) {
      colors.push(color8);
      data.append("image_9", selectedFile8);
    }
    data.append("color", JSON.stringify(colors));
    if (isCreate) {
      ProductsService.addProduct(data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    } else {
      ProductsService.updateProduct(product.id, data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    }
  };
  console.log(product);
  const backendUrl = "https://chelloshal-deutschland.de";
  useEffect(() => {
    console.log(backendUrl + "/upload/" + product.image);
    if (product && !!product.color && JSON.parse(product.color) != []) {
      let _colors = JSON.parse(product.color);
      console.log(_colors);
      setColor0(_colors[0] ?? "");
      setColor1(_colors[1] ?? "");
      setColor2(_colors[2] ?? "");
      setColor3(_colors[3] ?? "");
      setColor4(_colors[4] ?? "");
      setColor5(_colors[5] ?? "");
      setColor6(_colors[6] ?? "");
      setColor7(_colors[7] ?? "");
      setColor8(_colors[8] ?? "");
    }
    if (product && !!product.image) {
      setFile(backendUrl + "/upload/" + product.image);
      setStyle(Object.assign({}, imageStyle, { display: "inline-block" }));
    }
    if (product && !!product.image_2) {
      setFile1(backendUrl + "/upload/" + product.image_2);
      setStyle1(Object.assign({}, imageStyle1, { display: "inline-block" }));
    }
    if (product && !!product.image_3) {
      setFile2(backendUrl + "/upload/" + product.image_3);
      setStyle2(Object.assign({}, imageStyle2, { display: "inline-block" }));
    }
    if (product && !!product.image_4) {
      setFile3(backendUrl + "/upload/" + product.image4);
      setStyle3(Object.assign({}, imageStyle3, { display: "inline-block" }));
    }
    if (product && !!product.image_5) {
      setFile4(backendUrl + "/upload/" + product.image_5);
      setStyle4(Object.assign({}, imageStyle4, { display: "inline-block" }));
    }
    if (product && !!product.image_6) {
      setFile5(backendUrl + "/upload/" + product.image_6);
      setStyle5(Object.assign({}, imageStyle5, { display: "inline-block" }));
    }
    if (product && !!product.image_7) {
      setFile6(backendUrl + "/upload/" + product.image_7);
      setStyle6(Object.assign({}, imageStyle6, { display: "inline-block" }));
    }
    if (product && !!product.image_8) {
      setFile7(backendUrl + "/upload/" + product.image_8);
      setStyle7(Object.assign({}, imageStyle7, { display: "inline-block" }));
    }
    if (product && !!product.image_9) {
      setFile8(backendUrl + "/upload/" + product.image_9);
      setStyle8(Object.assign({}, imageStyle8, { display: "inline-block" }));
    }
  }, [product]);

  useEffect(() => {
    StoresService.getAllStores()
      .then((res) => {
        console.log(res);
        setStores(res.data);
        if (!isCreate) setStore(res.data.find((c) => c.id === initialValue?.store_id));
      })
      .catch((error) => {});
    CategoriesService.getAllCategories()
      .then((resp) => {
        setCategories(resp.data);
        if (!isCreate) setCategory(resp.data.find((c) => c.id === initialValue?.category_id));
        CouponsService.getAllCoupons()
          .then((resps) => {
            setCoupons(resps.data);
            if (!isCreate) setCoupon(resps.data.find((c) => c.id === initialValue?.coupon_id));
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, []);
  const [colors, setColors] = useState(
    !!product && product.color ? JSON.parse(product?.color) : []
  );
  // const addColors = (event, index) => {
  //   let value = event.target.value;
  //   if (colors.length > 1) {
  //     toast.warning(`You can't choose more than 1 colors for each image`);
  //   } else {
  //     let _colors = colors.slice();
  //     _colors.push({
  //       index: index,
  //     });
  //     setColors(_colors);
  //   }
  // };
  // const removeColor = (event, index) => {
  //   let _colors = colors.slice();
  //   _colors.splice(index, 1);
  //   setColors(_colors);
  // };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValue}
        validationSchema={productValidation}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            en_name,
            ar_name,
            de_name,
            en_description,
            ar_description,
            de_description,
            selling_price,
            unit_price,
            entity,
            brand,
            category_id,
            store_id,
          } = props.values;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالإنكليزي" : "English Name"}
                    name="en_name"
                    variant="outlined"
                    margin="dense"
                    value={en_name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="en_name" />}
                    error={props.errors.en_name && props.touched.en_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالعربي" : "Arabic Name"}
                    name="ar_name"
                    variant="outlined"
                    margin="dense"
                    value={ar_name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="ar_name" />}
                    error={props.errors.ar_name && props.touched.ar_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الاسم بالألماني" : "Deutch Name"}
                    name="de_name"
                    variant="outlined"
                    margin="dense"
                    value={de_name}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="de_name" />}
                    error={props.errors.de_name && props.touched.de_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالإنكليزي" : "English Description"}
                    name="en_description"
                    variant="outlined"
                    margin="dense"
                    value={en_description}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="en_description" />}
                    error={props.errors.en_description && props.touched.en_description}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالعربي" : "Arabic Description"}
                    name="ar_description"
                    variant="outlined"
                    margin="dense"
                    value={ar_description}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="ar_description" />}
                    error={props.errors.ar_description && props.touched.ar_description}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الوصف بالألماني" : "Deutch Description"}
                    name="de_description"
                    variant="outlined"
                    margin="dense"
                    value={de_description}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="de_description" />}
                    error={props.errors.de_description && props.touched.de_description}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "ماركة" : "Brand"}
                    name="brand"
                    variant="outlined"
                    margin="dense"
                    value={brand}
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="brand" />}
                    error={props.errors.brand && props.touched.brand}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "سعر المبيع ($)" : "($) Selling Price"}
                    name="selling_price"
                    variant="outlined"
                    margin="dense"
                    value={selling_price}
                    fullWidth
                    type="number"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="selling_price" />}
                    error={props.errors.selling_price && props.touched.selling_price}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "سعر الواحدة ($)" : "($) Unit Price"}
                    name="unit_price"
                    variant="outlined"
                    margin="dense"
                    value={unit_price}
                    fullWidth
                    type="number"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="unit_price" />}
                    error={props.errors.unit_price && props.touched.unit_price}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={direction == "rtl" ? "الكمية" : "Quantity"}
                    name="entity"
                    variant="outlined"
                    margin="dense"
                    value={entity}
                    fullWidth
                    type="number"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="entity" />}
                    error={props.errors.entity && props.touched.entity}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "الصنف" : "Category"}
                    name="category_id"
                    variant="outlined"
                    margin="dense"
                    value={category_id}
                    fullWidth
                    select
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="category_id" />}
                    error={props.errors.category_id && props.touched.category_id}
                    required
                  >
                    {categories != null &&
                      categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "المخزن" : "Store"}
                    name="store_id"
                    variant="outlined"
                    margin="dense"
                    value={store_id}
                    fullWidth
                    select
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="store_id" />}
                    error={props.errors.store_id && props.touched.store_id}
                    required
                  >
                    {stores != null &&
                      stores.map((st) => (
                        <MenuItem key={st.id} value={st.id}>
                          {st.en_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} md={10}>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "الكوبون" : "Coupon"}
                    name="coupon_id"
                    variant="outlined"
                    margin="dense"
                    value={coupon_id}
                    fullWidth
                    select
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="coupon_id" />}
                    error={props.errors.coupon_id && props.touched.coupon_id}
                  >
                    {coupons != null &&
                      coupons.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid> */}
                {
                  //   <Grid item xs={12} md={3}>
                  //   <TextField
                  //     type="color"
                  //     label={direction == "rtl" ? "اللون" : "Color"}
                  //     variant="outlined"
                  //     margin="dense"
                  //     fullWidth
                  //     onChange={addColors}
                  //   />
                  // </Grid>
                  //    <Grid item xs={12} md={3}>
                  //    {colors.length > 0 &&
                  //      colors.map((color, index) => (
                  //        <div key={index} style={{ display: "flex" }} className="flex-wrap sm:my-8">
                  //          {`(${index})`}
                  //          <input type="color" value={color} disabled={true} />
                  //          <button type="button" onClick={(event) => removeColor(event, index)}>
                  //            x
                  //          </button>
                  //        </div>
                  //      ))}
                  //  </Grid>
                }
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة الأولى" : "First Image"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color0}
                    fullWidth
                    select
                    onChange={(e) => {
                      setColor0(e.target.value);
                    }}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file}
                    width={150}
                    height={150}
                    style={style}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة الثانية (AR)" : "Secondary Image 2 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color1}
                    fullWidth
                    select
                    onChange={(e) => setColor1(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_2"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange1}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file1}
                    width={150}
                    height={150}
                    style={style1}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة الثالثة (AR)" : "Secondary Image 3 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color2}
                    fullWidth
                    select
                    onChange={(e) => setColor2(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_3"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange2}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file2}
                    width={150}
                    height={150}
                    style={style2}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة الرابعة (AR)" : "Secondary Image 4 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color3}
                    fullWidth
                    select
                    onChange={(e) => setColor3(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_4"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange3}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file3}
                    width={150}
                    height={150}
                    style={style3}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة الخامسة (AR)" : "Secondary Image 5 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color4}
                    fullWidth
                    select
                    onChange={(e) => setColor4(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_5"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange4}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file4}
                    width={150}
                    height={150}
                    style={style4}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة السادسة (AR)" : "Secondary Image 6 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color5}
                    fullWidth
                    select
                    onChange={(e) => setColor5(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_6"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange5}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file5}
                    width={150}
                    height={150}
                    style={style5}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة السابعة (AR)" : "Secondary Image 7 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color6}
                    fullWidth
                    select
                    onChange={(e) => setColor6(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_7"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange6}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file6}
                    width={150}
                    height={150}
                    style={style6}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة الثامنة (AR)" : "Secondary Image 8 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color7}
                    fullWidth
                    select
                    onChange={(e) => setColor7(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_8"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange7}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file7}
                    width={150}
                    height={150}
                    style={style7}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h6">
                    {direction == "rtl" ? "الصورة التاسعة (AR)" : "Secondary Image 9 (AR)"}
                  </Typography>
                  <TextField
                    style={{ height: "3em" }}
                    label={direction == "rtl" ? "اللون" : "Color"}
                    variant="outlined"
                    margin="dense"
                    value={color8}
                    fullWidth
                    select
                    onChange={(e) => setColor8(e.target.value)}
                  >
                    {colorsPalette != null &&
                      colorsPalette.map((cat) => (
                        <MenuItem style={{ color: cat.value }} key={cat.value} value={cat.value}>
                          {cat.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    name="image_9"
                    type="file"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange8}
                    onBlur={props.handleBlur}
                  />
                  <img
                    alt="product-image"
                    className="mt-2"
                    src={file8}
                    width={150}
                    height={150}
                    style={style8}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDButton variant="contained" type="submit" color="primary" fullWidth>
                    {direction == "rtl" ? "إضافة" : "Submit"}
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={backToPrevious}
                    variant="contained"
                    type="submit"
                    color="warning"
                    fullWidth
                  >
                    {direction == "rtl" ? "عودة" : "Back"}
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
