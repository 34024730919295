/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { TextField, Grid, MenuItem, Typography } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import MDButton from "components/MDButton";
import CategoriesService from "services/CategoriesService";
import CouponsService from "services/CouponsService";
import { toast } from "react-toastify";
import { LogoService } from "services/LogoService";
import { StoresService } from "services/StoresService";
export const CreateColorImages = ({ direction, product, isCreate, backToPrevious }) => {
  const FILE_SIZE = 524288;
  const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [colors, setColors] = useState([]);
  function handleChange(e) {
    setSelectedFile(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    imageStyle.display = "inline-block";
    setStyle(imageStyle);
  }
  const handleSubmit = () => {
    if (selectedFile == null) {
      toast.error("Image is required");
      return;
    }
    var data = new FormData();
    if (colors.length > 0) colors.map((e, index) => data.append(`color${index + 1}`, e));
    if (selectedFile) data.append("image_path", selectedFile);
    if (isCreate) {
      LogoService.addColorsAndLogo(data)
        .then((resp) => {
          toast.success(resp.message);
        })
        .catch((error) => toast.error("An error"));
    }
  };

  const addColors = (event) => {
    let value = event.target.value;
    if (colors.length > 3) {
      toast.warning(`You can't choose more than 4 colors`);
    } else {
      let _colors = colors.slice();
      _colors.push(value);
      setColors(_colors);
    }
  };
  const removeColor = (event, index) => {
    let _colors = colors.slice();
    _colors.splice(index, 1);
    setColors(_colors);
  };
  let imageStyle = {
    display: "none",
  };
  const [style, setStyle] = useState(imageStyle);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            type="color"
            label={direction == "rtl" ? "اللون" : "Color"}
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={addColors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {colors.length > 0 &&
            colors.map((color, index) => (
              <div key={index} style={{ display: "flex" }} className="flex-wrap sm:my-8">
                {`(${index})`}
                <input type="color" value={color} disabled={true} />
                <button type="button" onClick={(event) => removeColor(event, index)}>
                  x
                </button>
              </div>
            ))}
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="h6">
            {direction == "rtl" ? "الصورة الأولى" : "First Image"}
          </Typography>
          <TextField
            name="image"
            type="file"
            fullWidth
            variant="outlined"
            margin="dense"
            onChange={handleChange}
          />
          <img
            alt="product-image"
            className="mt-2"
            src={file}
            width={150}
            height={150}
            style={style}
          />
        </Grid>

        <Grid item xs={4}>
          <MDButton
            variant="contained"
            type="button"
            onClick={handleSubmit}
            color="primary"
            fullWidth
          >
            {direction == "rtl" ? "إضافة" : "Submit"}
          </MDButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
